enum LanguageColor {
  Python = '#3572A5',
  TypeScript = '#2B7489',
  JavaScript = '#F1E05A',
  'Unreal Engine' = 'black',
  Unity = 'black',
  HTML = '#e34c26',
  'C++' = '#f34b7d',
  'C#' = '#178600',
  PHP = '#4F5D95',
  Java = '#b07219',
  'Node.js' = '#3C873A',
  Django = '#092e20',
  MySQL = '#00758F',
  MariaDB = '#002d3b',
  MongoDB = '#589636',
  Qt = '#41CD52',
  Electron = '#89deee',
  Tensorflow = '#FFA800',
  'Jupyter Notebook' = '#f8702c',
  Docker = '#0db7ed',
  GraphQL = '#e12b9a',
  Steamworks = '#66c0f4',
  Discord = '#7289DA',
  'Google Maps API' = '#4285F4',
  'Google Cloud Platform' = '#4285F4',
  'AWS EC2' = '#FF9900',
  'AWS S3' = '#FF9900',
}

export default LanguageColor;
